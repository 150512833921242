// jekyll needs the dashes to process the sass file
@import "./bootstrap-custom.scss";
a {
  color: $primary;

  &:hover {
    color: #2e5aa5;
    text-decoration: none;
    transition: all 0.2s;
  }
}

// Sticky footer, see: https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/
html, body {
  height: 100vh;
}

main {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
  font-size: 14px;
}

.navbar-padding {
  // account for fixed navbar
  flex-shrink: 0;
  height: 78px;
}

.navbar-main {
  font-size: 0.9rem;
}

.navbar-toggler {
  border: 0;
}

.navbar .nav-link .badge {
  vertical-align: middle;
}

.border-2 {
  border-width: 2px !important;
}

.montserrat-bold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

#hero-section, #collection-hero {
  padding: 0.1px;
  position: relative;
}

.hero-diagonal {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 85%, 100% 0);
    clip-path: polygon(0 0, 0 100%, 100% 85%, 100% 0);
    background-color: $primary;
    z-index: -1;
  }
}

.img-company-logo {
  max-height: 60px;
}

#sponsor-list {
  img {
    padding-right: 90px;
  }

  img:last-child {
    padding-right: 0;
  }
}

.current-link {
  color: $primary !important;

  &:hover {
    color: $dark !important;
  }
}

.link-light {
  color: $light !important;

  &:hover {
    color: $primary !important;
  }
}

.link-dark {
  color: $dark !important;

  &:hover {
    color: $light !important;
  }
}

.link-dark-primary {
  color: $dark !important;

  &:hover {
    color: $primary !important;
  }
}


.video-padding {
  padding: 15% !important;
}

.collection-link {
  min-width: 12rem;
}

.collection-video {
  max-width: 700px;
}

.video-title {
  min-height: 4rem;
}

.quote-icon {
  position: absolute;
  left: 0.5rem;
  top: 0;
  opacity: 0.2;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

.page-link:hover {
  z-index: 2;
  color: $primary;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: $primary;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.btn-navbar {
  font-size: 0.9rem;
  font-family: 'Montserrat', sans-serif;
  padding: 0.325rem 1rem;
}

.markdown-content {
  h1, h2, h3, h4, h5, h6 {
    color: $primary;
  }

  h1 {
    margin-bottom: 2.25rem;
  }

  h2 {
    margin-bottom: 2rem;
  }

  h3 {
    margin-bottom: 1.75rem;
  }

  h4 {
    margin-bottom: 1.5rem;
  }
}

.blog-content {
  h1 {
   color: $primary
  }

  h2 {
    /* This makes sure that the titles don't disappear underneath the fixed
    header when jumped to from the overview */
    padding-top: 100px;
    margin-top: -80px;
  }

  h3 {
    color: #000000;
  }

  img {
    @extend .img-fluid;
  }

  .img-fluid {
    border: 1px solid #969494;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

pre {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 9.5px;
}

pre code {
  font-size: 90%;
}

// Table formatting

table {
  @extend .table;
  @extend .table-sm;
  @extend .table-striped;
  @extend .table-responsive-md;
}

thead {
  @extend .thead-dark;
}

table.fixed-table {
  width: 100%;
  table-layout: fixed;
}

// Font awesome

.fa-check {
  color: #1dbd21;
}

.fa-times {
  color: red;
}